import { Component } from '@angular/core';

@Component({
  selector: 'app-instagram-popover',
  template: `
    <ion-content class="ion-padding">
      <ion-icon name="arrow-up-outline" style="position: absolute; top: 10px; right: 10px; color: white;"></ion-icon>
      <p style="color: white; text-align: center;">
        Para proceder con la compra de la suscripción, por favor haz clic en el menú de Instagram y selecciona "Abrir en Navegador Externo"
      </p>
    </ion-content>
  `
})
export class InstagramPopoverComponent {}
